.cta {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 85vh;
}

@media screen and (min-width: 900px) {
  .cta {
    display: grid;
    grid-column-gap: 0.625rem;
    grid-template-columns: repeat(12, 7.35%);
    grid-template-rows: 2rem auto;
    margin: 6.25rem 0 0 0;
  }
}

.cta__container {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 900px) {
  .cta__container {
    grid-column-start: 2;
    grid-column-end: 12;
  }
}