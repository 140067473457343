.footer {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  margin: 0 auto 50px auto;
  max-width: 1340px;
}

.footer__list {
  display: flex;
  padding: 0;
  width: 100%;
  justify-content: space-between;
}

.footer__list li {
  padding: 0;
  text-indent: 0;
}

.footer__list li::before {
  display: none;
  text-indent: 0;
}

.footer__list__element {
  padding: 0 0.25rem;
}

@media screen and (min-width: 900px) {
  .footer__list__element {
    padding: 0 0.5rem;
  }
}

.footer__text {
  color: #b20074;
  font-family: sans-serif;
  font-size: 0.875rem;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
}

.footer__text--arrow::before {
  background-image: url("../images/arrow-up.png");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  top: -0.25rem;
  right: -1.75rem;
  width: 1.5rem;
  height: 1.5rem;
}