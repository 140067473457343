.copy {
  display: grid;
  grid-column-gap: 0.625rem;
  grid-template-columns: repeat(6, 13.6125%);
  grid-template-rows: 1fr;
  position: relative;
  padding-bottom: 8rem;
}

@media screen and (min-width: 800px) {
  .copy {
    grid-template-columns: repeat(12, 7.35%);
  }
}

.copy__title-container {
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 7;
  padding-top: 4rem;
  overflow: auto;
}

@media screen and (min-width: 800px) {
  .copy__title-container {
    grid-column-start: 2;
    grid-column-end: 12;
    padding-top: 0;
  }
}

.copy__text-container__copy {
  margin-top: 0;
}

.copy__text-container {
  grid-row: 3;
  grid-column-start: 2;
  grid-column-end: 7;
}

@media screen and (min-width: 800px) {
  .copy__text-container {
    grid-column-end: 9;
  }

  .copy__text-container__copy {
    grid-column-start: 2;
    grid-column-end: 8;
  }

  .copy__headline {
    margin-top: 6.25rem;
  }
}
