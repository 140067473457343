.bullets {
  align-items: center;
  position: relative;
  min-height: 100vh;
  padding-bottom: 8rem;
}

.bullets__container {
  display: grid;
  grid-column-gap: 0.625rem;
  grid-template-columns: repeat(6, 13.6125%);
  padding-top: 6.25rem;
}

@media screen and (min-width: 800px) {
  .bullets__container {
    grid-template-columns: repeat(12, 7.35%);
    margin-bottom: 6.25rem;
    padding-top: 0;
  }
}

.bullets__title-container {
  overflow: auto;
  grid-column-start: 1;
  grid-column-end: 7;
}

@media screen and (min-width: 800px) {
  .bullets__title-container {
    grid-column-start: 2;
    grid-column-end: 12;
  }
}

@media screen and (min-width: 800px) {
  .bullets__headline {
    margin-top: 6.25rem;
    grid-column-start: 2;
    grid-column-end: 13;
  }
}

.list__container {
  grid-row: 3;
  margin-bottom: 2rem;
}

@media screen and (min-width: 800px) {
  .list__container {
    margin-bottom: 0;
  }
}

.list__container--first {
  grid-column-start: 2;
  grid-column-end: 7;
}

@media screen and (min-width: 960px) {
  .list__container--first {
    grid-column-end: 6;
  }
}

.list__container--second {
  grid-row: 4;
  grid-column-start: 2;
  grid-column-end: 7;
  margin-bottom: 0;
}

@media screen and (min-width: 960px) {
  .list__container--second {
    grid-row: auto;
    grid-column-start: 8;
    grid-column-end: 12;
  }
}

.list__container__arrow {
  background-image: url("../images/arrow-right.png");
  background-repeat: no-repeat;
  background-size: contain;
  grid-column-start: 1;
  grid-column-end: 1;
  width: 1.5rem;
  height: 1.5rem;
}

.list__container__arrow_first {
  grid-row: 4;
}

.list__container__arrow_second {
  grid-row: 5;
}

@media screen and (min-width: 960px) {
  .list__container__arrow {
    grid-row: 3;
  }
  .list__container__arrow_first {
    grid-column-start: 7;
    grid-column-end: 8;
  }
}

.list__element {
  font-size: 14px;
  padding-bottom: 0.5rem;
}


@media screen and (min-width: 800px) {
  .list__element {
    font-size: 16px;
    padding-bottom: 1.5rem;
  }
}

.list__headline {
  margin-top: 0.25rem;
  padding-bottom: 0.5rem;
  max-width: 16rem;
}

@media screen and (min-width: 800px) {
  .list__headline {
    padding-bottom: 1rem;
  }
}