.gallery {
  position: relative;
  padding-bottom: 10rem;
}

@media screen and (min-width: 800px) {
  .gallery {
    margin-bottom: 6.25rem;
  }
}

.tile {
  align-self: flex-start;
}

.tile--text {
  background-color: #ffffff;
  font-size: 14px;
  line-height: 1.625em;
  padding: 1.5rem;
}

@media screen and (min-width: 800px) {
  .tile--text {
    margin-bottom: 0;
    padding: 3.75rem;
  }
}

.tile--first {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  grid-column-start: 2;
  grid-column-end: 7;
}

@media screen and (min-width: 800px) {
  .tile--first {
    margin-top: 3.125rem;
    grid-column-start: 3;
    grid-column-end: 7;
  }
}

.tile--second {
  margin-bottom: 3rem;
  grid-column-start: 2;
  grid-column-end: 7;
}

@media screen and (min-width: 800px) {
  .tile--second {
    margin-bottom: 0;
    margin-top: 8rem;
    grid-column-start: 7;
    grid-column-end: 11;
  }
}

.tile--third {
  grid-column-start: 2;
  grid-column-end: 7;
  margin-top: 1.25rem;
}

@media screen and (min-width: 800px) {
  .tile--third {
    grid-row: 2;
    grid-column-start: 3;
    margin-top: 3.125rem;
  }
}

.tile--last--grid {
  grid-row: 2;
  grid-column-start: 2;
  grid-column-end: 7;
  margin-top: 1.25rem;
}

@media screen and (min-width: 800px) {
  .tile--last--grid {
    grid-row: 2;
    grid-column-start: 7;
    grid-column-end: 11;
    margin-top: 6.25rem;
  }
}

.tile--left {
  margin-right: 1rem;
}

.tile__headline {
  margin-bottom: 3.125rem;
}

.tile__image {
  width: 100%;
}

.tile__link {
  display: inline-block;
  font-family: sans-serif;
  margin-top: 1.5rem;
}

.tiles {
  display: grid;
  grid-column-gap: 0.625rem;
  grid-template-columns: repeat(6, 13.6125%);
  padding-top: 6.25rem;
}

@media screen and (min-width: 800px) {
  .tiles {
    grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    grid-template-columns: repeat(12, 6.73%);
    grid-template-rows: repeat(2, auto);
    padding-top: 0;
  }
}
