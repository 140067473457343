.animated-line {
  background-color: #320096;
  position: absolute;
  bottom: 3.125rem;
  left: 50%;
  width: 1px;
  height: 2rem;
  animation-name: line;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  transform: scaleY(0);
  transform-origin: bottom;
  grid-row: 5;
}

@media screen and (min-width: 800px) {
  .animated-line {
    width: 2px;
  }
}

@keyframes line {
  0% {
    transform: scaleY(0)
  }
  50% {
    transform: scaleY(1)
  }
  100% {
    transform: scaleY(0)
  }
}

.arrow__sticky {
  background-color: #fff5eb;
  background-image: url("../images/arrow-right.png");
  background-repeat: no-repeat;
  background-size: contain;
  grid-row: 3;
  grid-column-start: 1;
  grid-column-end: 1;
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (min-width: 800px) {
  .arrow__sticky {
    position: sticky;
    top: 6.8rem;
  }
}

.arrow__sticky--gallery {
  grid-row: 2;
  margin-top: 1.25rem;
}

@media screen and (min-width: 800px) {
  .arrow__sticky--gallery {
    margin-top: 3.125rem;
  }
}

.text__sticky {
  background-color: #fff5eb;
  font-family: 'Moderat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 14px;
  font-weight: 400;
  grid-column-start: 1;
  grid-column-end: 7;
  position: sticky;
  top: 4.8rem;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .text__sticky {
    align-self: flex-start;
    font-size: 16px;
    grid-column-end: 12;
  }
}

.text__sticky--gallery {
  grid-column-start: 1;
  grid-column-end: 3;
}

#sticky-copy,
#sticky-bullets,
#sticky-about,
#sticky-network,
#sticky-hero {
  transition: all 0.25s linear;
}

.is-animated-out {
  transform: translateY(-2rem);
  opacity: 0;
}

.is-hidden {
  transform: translateY(-2rem);
  opacity: 0;
  visibility: hidden;
}

.is-sticky {
  position: absolute;
  top: 2.45rem;
  left: 1.5rem;
}

@media screen and (min-width: 800px) {
  .is-sticky {
    top: 5.1rem;
    left: 3rem;
  }
}