@font-face {
  font-family: 'Moderat';
  font-weight: 300;
  src: url('./fonts/Moderat-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Moderat-Light.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'Moderat';
  font-weight: 400;
  src: url('./fonts/Moderat-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Moderat-Regular.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'Tiempos';
  font-weight: 400;
  src: url('./fonts/tiempos-text-web-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/tiempos-text-web-regular.woff') format('woff'); /* Pretty Modern Browsers */
}

* {
  box-sizing: border-box;
}

#root {
  margin: 0 1.5rem;
  max-width: 1340px;
}

@media screen and (min-width: 450px) {
  #root {
    margin: 0 3rem;
  }
}

@media screen and (min-width: 1436px) {
  #root {
    margin: 0 auto;
  }
}

  html,
  body {
    max-width: 100% !important;
    overflow-x: hidden !important;
    height: 100vh;
  }

body {
  background-color: #FFF5EB;
  color: #320096;
  font-family: 'Tiempos', serif;
  font-size: 16px;
  line-height: 1.625em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5em;
  margin: 0;
  word-break: break-word;
}

button {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  outline: none;
  border: none;
  padding: 0;
}

button:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

address, h1, h2, h3 {
  font-family: 'Moderat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 1.2em;
  margin-top: 2.1rem;
}

address,
h1 {
  font-size: 1.375rem;
}

@media screen and (min-width: 450px) {
  address,
  h1 {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 600px) {
  address,
  h1 {
    font-size: 2.25rem;
  }
}

@media screen and (min-width: 900px) {
  address,
  h1 {
    font-size: 3.125rem;
  }
}

h2 {
  font-size: 1.25rem;
  font-weight: 400;
}

h3 {
  font-weight: 500;
}

ul {
  list-style: none;
  margin: 0;
  padding-left: 0.75rem;
}

ul > li {
  text-indent: -0.75rem;
}

ul > li::before {
  content: "– ";
  text-indent: -0.75rem;
}

@media screen and (min-width: 900px) {
  ul {
    list-style: none;
    padding: 0;
  }

  ul > li {
    text-indent: 0;
  }
  
  ul > li::before {
    display: none;
  }
}
@media screen and (min-width: 900px) {
  .is-hidden-on-desktop {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .is-hidden-on-mobile {
    display: none;
  }
}

.not-wrappable {
  white-space: nowrap;
}

.text__underlined {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.text__underlined:hover,
  .text__underlined:active,
  .text__underlined:focus {
    color: #b20074;
  }

@media (hover:none) {
  .text__underlined:hover,
  .text__underlined:active,
  .text__underlined:focus {
    color: #320096;
  }
}

.text__underlined::after {
  background-color: #320096;
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
}

@media screen and (min-width: 900px) {
  .text__underlined::after {
    height: 2px;
  }
}

.text__underlined:hover::after {
  background-color: #b20074;
  height: 2px;
}

.text__underlined--fine::after {
  bottom: -2px;
  height: 1px;
}

.text__underlined--fine:hover::after {
  bottom: -2px;
  height: 1px;
}

.text__underlined--orange:hover {
  color: #320096;
}

@media (hover:none) {
  .text__underlined--orange:hover {
    color: #b20074;
  }
}

.text__underlined--orange::after {
  background-color: #b20074;
}

.text__underlined--orange:hover::after {
  background-color: #320096;
}

@media (hover:none) {
  .text__underlined--orange:hover::after {
    background-color: #b20074;
  }
}