.contact {
  background-color: #320096;
  color: #FFF5EB;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.contact__close {
  background-image: url("../images/menu-close.png");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 3.25rem;
  height: 3.25rem;
}

.contact__header {
  margin: 50px 0 0 0;
  position: fixed;
  top: 0;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 1;
}

@media screen and (min-width: 450px) {
  .contact__header {
    left: 3rem;
    right: 3rem;
  }
}

.contact__header__text {
  font-family: 'Moderat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}