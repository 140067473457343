.header {
  background-color: #FFF5EB;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

@media screen and (min-width: 450px) {
  .header {
    padding: 0 3rem;
  }
}