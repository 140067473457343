.imprint {
  min-height: 100vh;
  margin-bottom: 6.25rem;
}

@media screen and (min-width: 900px) {
  .imprint {
    display: grid;
    grid-column-gap: 0.625rem;
    grid-template-columns: repeat(12, 7.35%);
  }
}

@media screen and (min-width: 900px) {
  .imprint__container {
    grid-column-start: 2;
    grid-column-end: 12;
  }
}

.imprint__headline {
  margin-top: 12.5rem;
  margin-bottom: 3rem;
}