.hero {
  display: grid;
  grid-column-gap: 0.625rem;
  grid-template-columns: repeat(6, 13.6125%);
  grid-template-rows: 2rem auto;
  position: relative;
  padding: 3.125rem 0;
  min-height: 100vh;
}

@media screen and (min-width: 800px) {
  .hero {
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 6.25rem;
    width: 100%;
  }
}

.hero__container {
  align-items: center;
  display: flex;
  flex-grow: 1;
  grid-column-start: 1;
  grid-column-end: 7;
}

@media screen and (min-width: 800px) {
  .hero__container {
    grid-column-start: 2;
    grid-column-end: 12;
  }
}

.hero__container__headline {
  margin-top: 3.125rem;
}

@media screen and (min-width: 800px) {
  .hero__container__headline {
    margin-top: 6.25rem;
  }
}

.hero__tooltip {
  background-color: #FFD3D8;
  font-size: 1rem;
  line-height: 1rem;
  padding: 1rem;
  position: absolute;
  top: calc(-100% - 2rem);
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 450px) {
  .hero__tooltip {
    top: calc(-100% - 1rem);
  }
}

@media screen and (min-width: 600px) {
  .hero__tooltip {
    top: -100%;
  }
}

.hero__tooltip--mobile {
  padding-left: 1.75rem;
}

.hero__tooltip--mobile:after {
  background-image: url("../images/arrow-minimal.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  content: "";
  display: block;
  width: 0.6rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
}

.hero__tooltip:hover {
  color: #320096;
}

.hero__tooltip:before {
  content: "";
  display: block;
  width: 0; 
  height: 0; 
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid #FFD3D8;
  position: absolute;
  bottom: -0.45rem;
  left: 50%;
  transform: translateX(-50%);
}
